import environment from "environments/environment";

export const ssoServiceRedirect = async () => {
    try {
        const response = await fetch(`${environment.ZION_URL}/sso/redirectUrl`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch SSO redirect URL');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching SSO redirect URL:', error);
        throw error;
    }
};

export const ssoLogout= async () => {
    try {
        const response = await fetch(`${environment.ZION_URL}/sso/logout`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch SSO redirect URL');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching SSO redirect URL:', error);
        throw error;
    }
};