import { ToastError, ToastErrorWithDescription } from './toast.service';

export const handleError = async (err: Response, disableToastOnError: boolean) => {
  let res: any;
  try {
    res = await err.json();
  } catch (e) {
    res = { error: 'Failed to parse JSON', message: 'Unexpected end of JSON input' };
  }
  res.status = err.status;
  if (res.error && !disableToastOnError) {
    if (res.message) ToastErrorWithDescription(res.error, res.message);
    else ToastError(res.error);
  } else if (res.message && !disableToastOnError) {
    ToastError(res.message);
  } else if (res && !res.error && !res.message && res.payload && !disableToastOnError) {
    ToastError(res.payload);
  } else if (res.parameterViolations && res.parameterViolations.length > 0) {
    res.parameterViolations.map((ele: any) => ToastError(ele.message));
  } else if (res && !res.error && !res.message && !disableToastOnError) {
    ToastError('Something went wrong!');
  }
  throw res;
};
